<template>
<div class="wrapper domain_content blogIndex bgcolor">
    <section class="blog-list-container">
        <h1 style="text-align: center;">Blog</h1>

        <div class="blog-list-row">
            <div @click="go(data.url)" class="blog" v-for="(data, i) in sortedBlogList" :key="i">
                <div class="blog-list-item">
                    <!-- <div class="blog-list-img"> -->
                    <img class="blog-list-img" :src="data.img" :alt="data.alt"/>
                    <!-- </div> -->
                    <div class="blog-list-content">
                        <div class="blog-list-title">
                            {{ data.title }}
                        </div>
                        <div class="blog-list-descript">
                            {{ data.desc }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import '@/css/default.scss'
import doc from '@/documents/blog3.js'
import {
    mapGetters
} from 'vuex'
const title = 'GBWhatsApp(GBWA) Blogs with High Debate Topics'
const description = 'Are you searching for information about GBWhatsApp? If you\'re interested, you can enter this page to read blogs related to GB WhatsApp hot debate topics.'
export default {
    metaInfo: {
        title,
        meta: [{
                name: 'description',
                content: description
            },
            {
                name: 'title',
                content: title,
            },
            {
                property: "og:title",
                content: title
            },
            {
                property: "og:description",
                content: description
            },
        ],
            "link": [{
                "rel": "canonical",
                "href": "https://gbwhatsapk.net/blogs/"
            }]
    },

    data() {
        return {
            doc: doc,
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
        }
    },
    computed: {
        ...mapGetters(['showDebug', 'host', 'downloadicon', 'deviceType']),
        sortedBlogList() {
            return this.doc.blogList.slice().reverse();
        }
    },
    created() {
        this.initParams()
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        go(url) {
            // const urlMap = this.doc.urlMap
            this.$router.push(url)
            // const url = this.$router.resolve({
            // 	path: urlMap[index],
            // })
            // console.log(url.href, 8888) // 输出为#/test-url
            // // 打开新窗口
            // window.open(url.href)
        },
        download(apk) {
            this.$global.download(apk)
        },
        initParams() {
            this.from = this.$global.GetQueryString('from')
            this.filename = this.$global.GetQueryString('filename')
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename)
            } else {
                this.getAPK()
                this.getOfficialApk()
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk)
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename,
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners
                        this.updateTime = res.data.updateTime
                        this.clicks = res.data.clicks
                        this.pageIntSuccess = true
                        if (isFromQuery) {
                            this.apk = res.data.apk
                            this.updateAPK()
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err)
                })
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download',
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk
                        this.updateAPK()
                        console.log('APK fetched successfully')
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err)
                })
        }
    },
}
</script>
